import { submenusWhichAction } from '../utils/SubmenusUtils';
import type { SUBMENUS } from '../utils/SubmenusUtils';

export const doChangeCollapseUnmounted = (payload: boolean) =>
  ({
    type: 'changeCollapseUnmounted',
    payload,
  }) as IAction;

export const doSetIsUnwantedTouch = (payload: boolean) =>
  ({
    type: 'setIsUnwantedTouch',
    payload,
  }) as IAction;
export const doChangeHoverState = (type: string, payload: boolean) =>
  ({
    type,
    payload,
  }) as IAction;
export const doSetCurrentSub = (sub: SUBMENUS | null) =>
  ({
    type: 'setCurrentSubmenu',
    payload: sub,
  }) as IAction;
export const doOnSubmenuClose = (sub: SUBMENUS | null) =>
  ({
    type: `onSubmenuClose_${
      sub !== null ? submenusWhichAction[sub] : 'default'
    }`,
    payload: sub,
  }) as IAction;

export interface IHeaderDesktopReducerState {
  isCollapseUnmounted: boolean;
  servicesHover: boolean;
  careersHover: boolean;
  resourceHover: boolean;
  productsHover: boolean;
  prevSubmenu: null | SUBMENUS;
  currentSubmenu: null | SUBMENUS;
  isUnwantedTouch: boolean;
}

export const initialState = {
  isCollapseUnmounted: true,
  prevSubmenu: null,
  currentSubmenu: null,
  isUnwantedTouch: false,
  servicesHover: false,
  careersHover: false,
  productsHover: false,
  resourceHover: false,
};

type IActionChangeCollapseUnmounted = {
  type: 'changeCollapseUnmounted';
  payload: IHeaderDesktopReducerState['isCollapseUnmounted'];
};
type IActionSetServicesHover = {
  type: 'setServicesHover';
  payload: IHeaderDesktopReducerState['servicesHover'];
};
type IActionSetCareersHover = {
  type: 'setCareersHover';
  payload: IHeaderDesktopReducerState['careersHover'];
};
type IActionSetProductsHover = {
  type: 'setProductsHover';
  payload: IHeaderDesktopReducerState['resourceHover'];
};
type IActionSetResourceHover = {
  type: 'setResourceHover';
  payload: IHeaderDesktopReducerState['resourceHover'];
};
type IActionSetPrevSubmenu = {
  type: 'setPrevSubmenu';
  payload: IHeaderDesktopReducerState['prevSubmenu'];
};
type IActionSetCurrentSubmenu = {
  type: 'setCurrentSubmenu';
  payload: IHeaderDesktopReducerState['currentSubmenu'];
};
type IActionSetIsUnwantedTouch = {
  type: 'setIsUnwantedTouch';
  payload: IHeaderDesktopReducerState['isUnwantedTouch'];
};
type IActionOnSubmenuCloseSetProductsHover = {
  type: 'onSubmenuClose_setProductsHover';
  payload: IHeaderDesktopReducerState['prevSubmenu'];
};
type IActionOnSubmenuCloseSetServicesHover = {
  type: 'onSubmenuClose_setServicesHover';
  payload: IHeaderDesktopReducerState['prevSubmenu'];
};
type IActionOnSubmenuCloseSetCareersHover = {
  type: 'onSubmenuClose_setCareersHover';
  payload: IHeaderDesktopReducerState['prevSubmenu'];
};
type IActionOnSubmenuCloseSetResourceHover = {
  type: 'onSubmenuClose_setResourceHover';
  payload: IHeaderDesktopReducerState['prevSubmenu'];
};

export type IAction =
  | IActionChangeCollapseUnmounted
  | IActionSetServicesHover
  | IActionSetCareersHover
  | IActionSetResourceHover
  | IActionSetPrevSubmenu
  | IActionSetCurrentSubmenu
  | IActionSetIsUnwantedTouch
  | IActionOnSubmenuCloseSetServicesHover
  | IActionOnSubmenuCloseSetCareersHover
  | IActionSetProductsHover
  | IActionOnSubmenuCloseSetProductsHover
  | IActionOnSubmenuCloseSetResourceHover;

export function reducer(
  state: IHeaderDesktopReducerState,
  action: IAction
): IHeaderDesktopReducerState {
  switch (action.type) {
    case 'changeCollapseUnmounted': {
      return {
        ...state,
        isCollapseUnmounted: action.payload,
      };
    }
    case 'setServicesHover': {
      return {
        ...state,
        servicesHover: action.payload,
      };
    }
    case 'setCareersHover': {
      return {
        ...state,
        careersHover: action.payload,
      };
    }
    case 'setResourceHover': {
      return {
        ...state,
        resourceHover: action.payload,
      };
    }
    case 'setProductsHover': {
      return {
        ...state,
        productsHover: action.payload,
      };
    }

    case 'setPrevSubmenu': {
      return {
        ...state,
        prevSubmenu: action.payload,
      };
    }
    case 'setCurrentSubmenu': {
      return {
        ...state,
        currentSubmenu: action.payload,
      };
    }

    case 'setIsUnwantedTouch': {
      return {
        ...state,
        isUnwantedTouch: action.payload,
      };
    }
    case 'onSubmenuClose_setServicesHover': {
      return {
        ...state,
        prevSubmenu: action.payload,
        currentSubmenu: null,
        servicesHover: false,
      };
    }
    case 'onSubmenuClose_setProductsHover': {
      return {
        ...state,
        prevSubmenu: action.payload,
        currentSubmenu: null,
        productsHover: false,
      };
    }
    case 'onSubmenuClose_setCareersHover': {
      return {
        ...state,
        prevSubmenu: action.payload,
        currentSubmenu: null,
        careersHover: false,
      };
    }
    case 'onSubmenuClose_setResourceHover': {
      return {
        ...state,
        prevSubmenu: action.payload,
        currentSubmenu: null,
        resourceHover: false,
      };
    }
    default: {
      return { ...state };
    }
  }
}
