import type { Palette, PaletteColor } from '@mui/material';
import { colorsDark, colorsLight } from './colors';
declare module '@mui/material/styles/createPalette' {}
declare module '@mui/material/styles/createPalette' {
  interface contentPallete {
    PRIMARY: string;
    SECONDARY: string;
    TETRIARY: string;
    DISABLED: string;
  }
  interface borderPallete {
    STRONG: string;
    MEDIUM: string;
    LIGHT: string;
  }
  interface actionPallete {
    ENABLED: string;
    HOVERED: string;
    PRESSED: string;
    SELECTED: string;
  }
  interface backgroundPallete {
    INVERTED: string;
    STRONG: string;
    MUTED: string;
    ON_SUBTLE: string;
    SUBTLE: string;
    SURFACE: string;
  }
  interface IShortColors {
    _0: string;
    PLUS_1: string;
    PLUS_2: string;
    PLUS_3: string;
  }
  interface ILongColors {
    MINUS_4: string;
    MINUS_3: string;
    MINUS_2: string;
    MINUS_1: string;
    _0: string;
    PLUS_1: string;
    PLUS_2: string;
    PLUS_3: string;
    PLUS_4: string;
    PLUS_5: string;
    PLUS_6: string;
    PLUS_7: string;
    PLUS_8: string;
  }
  interface TypeBackground extends backgroundPallete {}
  interface Palette {
    type: 'navy' | 'light' | 'dark';
    greyContent?: contentPallete;
    greyBorder?: borderPallete;
    greyAction?: actionPallete;
    greySubtleAction?: actionPallete;
    greyOutlineAction?: actionPallete;
    greyBackgroundColor?: backgroundPallete;
    invertedContent?: contentPallete;
    invertedBorder?: borderPallete;
    invertedAction?: actionPallete;
    invertedSubtleAction?: actionPallete;
    invertedOutlineAction?: actionPallete;
    invertedBackgroundColor?: backgroundPallete;
    greyColors?: ILongColors;
    blueColors?: IShortColors;
    raspberryColors?: IShortColors;
    magentaColors?: IShortColors;
    purpleColors?: IShortColors;
    redColors?: IShortColors;
    grapeColors?: IShortColors;
    violetColors?: IShortColors;
    cyanColors?: IShortColors;
    tealColors?: IShortColors;
    aquamarineColors?: IShortColors;
    greenColors?: IShortColors;
    emeraldColors?: IShortColors;
    orangeColors?: IShortColors;
    // yellowColors?: IShortColors;
    neutralColors?: IShortColors;
    invertedColors?: ILongColors;
  }
}

export const paletteDark: Palette = {
  type: 'dark',
  text: {
    primary: '#1C1F22',
  },
  background: {
    default: '#070E15',
  },

  ...colorsDark,
} as unknown as Palette;

export const paletteLight: Palette = {
  type: 'light',
  text: {
    primary: '#1C1F22',
  },
  background: {
    default: '#FFF',
  },

  ...colorsLight,
} as unknown as Palette;
