import {
  submenuActionStateValues,
  submenusWhichAction,
} from '../utils/HeaderSubmenusUtils';
import type { SUBMENUS } from '../utils/HeaderSubmenusUtils';

export type OnSubmenuNameHoverType = {
  subHover: boolean;
  sub: SUBMENUS | null;
};
export type OnSubmenuHoverType = {
  whichSubmenu: string;
  newValue: boolean;
  shouldCloseSub: boolean;
};
export const doChangeCollapseUnmounted = (payload: boolean) =>
  ({
    type: 'changeCollapseUnmounted',
    payload,
  }) as IAction;

export const doSetShouldCloseSubmenu = (payload: boolean) =>
  ({
    type: 'setShouldCloseSubmenu',
    payload,
  }) as IAction;
export const doSetIsUnwantedTouch = (payload: boolean) =>
  ({
    type: 'setIsUnwantedTouch',
    payload,
  }) as IAction;
export const doChangeHoverState = (type: string, payload: boolean) =>
  ({
    type,
    payload,
  }) as IAction;
export const doOnSubmenuNameEnter = (payload: OnSubmenuNameHoverType) =>
  ({
    type: 'onSubmenuNameEnter',
    payload,
  }) as IAction;
export const doOnSubmenuEnter = (payload: OnSubmenuHoverType) =>
  ({
    type: 'onSubmenuEnter',
    payload,
  }) as IAction;
export const doOnSetInitialState = () =>
  ({
    type: 'onSetInitialState',
  }) as IAction;
export const doOnSubmenuNameLeave = (payload: OnSubmenuNameHoverType) =>
  ({
    type: 'onSubmenuNameLeave',
    payload,
  }) as IAction;
export const doSetCurrentSub = (sub: SUBMENUS | null) =>
  ({
    type: 'setCurrentSubmenu',
    payload: sub,
  }) as IAction;
export const doOnSubmenuClose = (sub: SUBMENUS | null) =>
  ({
    type: `onSubmenuClose_${
      sub !== null ? submenusWhichAction[sub] : 'default'
    }`,
    payload: sub,
  }) as IAction;

export interface IHeaderDesktopReducerState {
  isSubmenuNameHover: boolean;
  shouldCloseSubmenu: boolean;
  isCollapseUnmounted: boolean;
  servicesHover: boolean;
  careersHover: boolean;
  resourcesHover: boolean;
  productsHover: boolean;
  prevSubmenu: null | SUBMENUS;
  currentSubmenu: null | SUBMENUS;
  isUnwantedTouch: boolean;
}

export const initialState = {
  shouldCloseSubmenu: false,
  isSubmenuNameHover: false,
  isCollapseUnmounted: true,
  prevSubmenu: null,
  currentSubmenu: null,
  isUnwantedTouch: false,
  servicesHover: false,
  careersHover: false,
  productsHover: false,
  resourcesHover: false,
};

type IActionOnSetInitialState = {
  type: 'onSetInitialState';
};

type IActionOnSubmenuEnter = {
  type: 'onSubmenuEnter';
  payload: OnSubmenuHoverType;
};
type IActionOnSubmenuNameEnter = {
  type: 'onSubmenuNameEnter';
  payload: OnSubmenuNameHoverType;
};
type IActionOnSubmenuNameLeave = {
  type: 'onSubmenuNameLeave';
  payload: OnSubmenuNameHoverType;
};

type IActionShouldCloseSubmenu = {
  type: 'setShouldCloseSubmenu';
  payload: IHeaderDesktopReducerState['shouldCloseSubmenu'];
};
type IActionChangeCollapseUnmounted = {
  type: 'changeCollapseUnmounted';
  payload: IHeaderDesktopReducerState['isCollapseUnmounted'];
};
type IActionSetServicesHover = {
  type: 'setServicesHover';
  payload: IHeaderDesktopReducerState['servicesHover'];
};
type IActionSetCareersHover = {
  type: 'setCareersHover';
  payload: IHeaderDesktopReducerState['careersHover'];
};
type IActionSetProductsHover = {
  type: 'setProductsHover';
  payload: IHeaderDesktopReducerState['productsHover'];
};
type IActionSetResourcesHover = {
  type: 'setResourcesHover';
  payload: IHeaderDesktopReducerState['resourcesHover'];
};
type IActionSetPrevSubmenu = {
  type: 'setPrevSubmenu';
  payload: IHeaderDesktopReducerState['prevSubmenu'];
};
type IActionSetCurrentSubmenu = {
  type: 'setCurrentSubmenu';
  payload: IHeaderDesktopReducerState['currentSubmenu'];
};
type IActionSetIsUnwantedTouch = {
  type: 'setIsUnwantedTouch';
  payload: IHeaderDesktopReducerState['isUnwantedTouch'];
};
type IActionOnSubmenuCloseSetProductsHover = {
  type: 'onSubmenuClose_setProductsHover';
  payload: IHeaderDesktopReducerState['prevSubmenu'];
};
type IActionOnSubmenuCloseSetServicesHover = {
  type: 'onSubmenuClose_setServicesHover';
  payload: IHeaderDesktopReducerState['prevSubmenu'];
};
type IActionOnSubmenuCloseSetCareersHover = {
  type: 'onSubmenuClose_setCareersHover';
  payload: IHeaderDesktopReducerState['prevSubmenu'];
};
type IActionOnSubmenuCloseSetResourcesHover = {
  type: 'onSubmenuClose_setResourcesHover';
  payload: IHeaderDesktopReducerState['prevSubmenu'];
};

export type IAction =
  | IActionOnSetInitialState
  | IActionOnSubmenuEnter
  | IActionOnSubmenuNameLeave
  | IActionOnSubmenuNameEnter
  | IActionShouldCloseSubmenu
  | IActionChangeCollapseUnmounted
  | IActionSetServicesHover
  | IActionSetCareersHover
  | IActionSetResourcesHover
  | IActionSetPrevSubmenu
  | IActionSetCurrentSubmenu
  | IActionSetIsUnwantedTouch
  | IActionOnSubmenuCloseSetServicesHover
  | IActionOnSubmenuCloseSetCareersHover
  | IActionSetProductsHover
  | IActionOnSubmenuCloseSetProductsHover
  | IActionOnSubmenuCloseSetResourcesHover;

export function reducer(
  state: IHeaderDesktopReducerState,
  action: IAction
): IHeaderDesktopReducerState {
  switch (action.type) {
    case 'onSubmenuEnter': {
      return {
        ...state,
        shouldCloseSubmenu: action.payload.shouldCloseSub,
        [submenuActionStateValues[
          action.payload.whichSubmenu as keyof typeof submenuActionStateValues
        ] as keyof IHeaderDesktopReducerState]: action.payload.newValue,
      };
    }
    case 'onSubmenuNameEnter': {
      return {
        ...state,
        currentSubmenu: action.payload.sub,
        isSubmenuNameHover: action.payload.subHover,
      };
    }
    case 'onSubmenuNameLeave': {
      return {
        ...state,
        prevSubmenu: action.payload.sub,
        currentSubmenu: null,
        [`${action.payload.sub}Hover`]: false,
        isSubmenuNameHover: action.payload.subHover,
      };
    }
    case 'changeCollapseUnmounted': {
      return {
        ...state,
        isCollapseUnmounted: action.payload,
      };
    }
    case 'setServicesHover': {
      return {
        ...state,
        servicesHover: action.payload,
      };
    }

    case 'setShouldCloseSubmenu': {
      return {
        ...state,
        shouldCloseSubmenu: action.payload,
      };
    }
    case 'setCareersHover': {
      return {
        ...state,
        careersHover: action.payload,
      };
    }
    case 'setResourcesHover': {
      return {
        ...state,
        resourcesHover: action.payload,
      };
    }
    case 'setProductsHover': {
      return {
        ...state,
        productsHover: action.payload,
      };
    }

    case 'setPrevSubmenu': {
      return {
        ...state,
        prevSubmenu: action.payload,
      };
    }
    case 'setCurrentSubmenu': {
      return {
        ...state,
        currentSubmenu: action.payload,
      };
    }

    case 'setIsUnwantedTouch': {
      return {
        ...state,
        isUnwantedTouch: action.payload,
      };
    }
    case 'onSubmenuClose_setServicesHover': {
      return {
        ...state,
        prevSubmenu: action.payload,
        currentSubmenu: null,
        servicesHover: false,
      };
    }
    case 'onSubmenuClose_setProductsHover': {
      return {
        ...state,
        prevSubmenu: action.payload,
        currentSubmenu: null,
        productsHover: false,
      };
    }
    case 'onSubmenuClose_setCareersHover': {
      return {
        ...state,
        prevSubmenu: action.payload,
        currentSubmenu: null,
        careersHover: false,
      };
    }
    case 'onSubmenuClose_setResourcesHover': {
      return {
        ...state,
        prevSubmenu: action.payload,
        currentSubmenu: null,
        resourcesHover: false,
      };
    }
    case 'onSetInitialState': {
      return {
        ...initialState,
      };
    }
    default: {
      return { ...state };
    }
  }
}
