import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';
import React, { useEffect, useState } from 'react';
import { Footer } from './Footer';
import MobileHeader from './MobileHeader';
import PopUp from './PopUp';
import WebHeader from './WebHeader';

const PREFIX = 'PageLayout';

const classes = {
  root: `${PREFIX}-root`,
  headerGridContainer: `${PREFIX}-headerGridContainer`,
  headerNavigation: `${PREFIX}-headerNavigation`,
  headerChildren: `${PREFIX}-headerChildren`,
};

const PageLayoutWrapper = styled('div')(({
  theme: { palette, template, breakpoints },
}) => {
  return {
    [`& .${classes.root}`]: {
      color: palette.primary.main,
    },
    [`& .${classes.headerGridContainer}`]: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
    },
    [`& .${classes.headerNavigation}`]: {
      zIndex: 1000,
      position: 'fixed',
      width: '100vw',
      top: 0,
    },
    [`& .${classes.headerChildren}`]: {
      marginTop: `calc(${template.webHeaderHeight} + (calc(${template.webHeaderHeightOnTop} - ${template.webHeaderHeight})))`,

      [breakpoints.down('lg')]: {
        marginTop: `${template.webHeaderHeight}`,
      },
    },
  };
});

export const PageLayout: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [isOnTop, setIsOnTop] = useState(true);

  useEffect(() => {
    window.onscroll = () => {
      setIsOnTop(window.pageYOffset <= 10);
    };
  }, []);

  return (
    <PageLayoutWrapper>
      <div className={classes.headerGridContainer}>
        <nav className={classes.headerNavigation}>
          <Box display={{ xs: 'none', lg: 'block' }}>
            <WebHeader isOnTop={isOnTop} />
          </Box>
          <Box display={{ xs: 'block', lg: 'none' }}>
            <MobileHeader />
          </Box>
        </nav>
        <main className={classes.headerChildren}>{children}</main>
        <Footer />
      </div>
    </PageLayoutWrapper>
  );
};
