import type { Components } from '@mui/material';
import { breakpoints } from './breakpoints';
export const overrides: Components = {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontFamily: 'Satoshi',
      },
    },
  },
  MuiCssBaseline: {},
};
