export const doOnSetShouldOpenMenu = (payload: boolean) =>
  ({
    type: 'onSetShouldOpenMenu',
    payload,
  }) as IAction;
export const doOnSetIsSubmenuClose = (payload: boolean) =>
  ({
    type: 'onSetIsSubmenuClose',
    payload,
  }) as IAction;
export const doOnSetExpanded = (payload: string | false) =>
  ({
    type: 'onSetExpanded',
    payload,
  }) as IAction;

export const doOnSetNextSubmenu = (payload: string | false) =>
  ({
    type: 'onSetNextSubmenu',
    payload,
  }) as IAction;

export const doOnOpenNextSubmenu = (payload: string | false) =>
  ({
    type: 'onOpenNextSubmenu',
    payload,
  }) as IAction;

export const doOnSetInitialState = () =>
  ({
    type: 'onSetInitialState',
  }) as IAction;

export interface IHeaderMobileReducerState {
  shouldOpenMenu: boolean;
  expanded: string | false;
  isSubmenuClose: boolean;
  nextSubmenu: string | false;
}

export const initialStateMobileHeader = {
  shouldOpenMenu: false,
  expanded: false as const,
  isSubmenuClose: true,
  nextSubmenu: false as const,
};
type IActionOnSetShouldOpenMenu = {
  type: 'onSetShouldOpenMenu';
  payload: IHeaderMobileReducerState['shouldOpenMenu'];
};
type IActionOnSetIsSubmenuClose = {
  type: 'onSetIsSubmenuClose';
  payload: IHeaderMobileReducerState['isSubmenuClose'];
};

type IActionOnSetExpanded = {
  type: 'onSetExpanded';
  payload: IHeaderMobileReducerState['expanded'];
};
type IActionOnSetNextSubmenu = {
  type: 'onSetNextSubmenu';
  payload: IHeaderMobileReducerState['nextSubmenu'];
};

type IActionOnOpenNextSubmenu = {
  type: 'onOpenNextSubmenu';
  payload: IHeaderMobileReducerState['nextSubmenu'];
};

type IActionOnSetInitialState = {
  type: 'onSetInitialState';
};

export type IAction =
  | IActionOnSetShouldOpenMenu
  | IActionOnSetIsSubmenuClose
  | IActionOnSetExpanded
  | IActionOnSetNextSubmenu
  | IActionOnOpenNextSubmenu
  | IActionOnSetInitialState;

export function reducerMobileHeader(
  state: IHeaderMobileReducerState,
  action: IAction
): IHeaderMobileReducerState {
  switch (action.type) {
    case 'onSetShouldOpenMenu': {
      return {
        ...state,
        shouldOpenMenu: action.payload,
      };
    }
    case 'onSetIsSubmenuClose': {
      return {
        ...state,
        isSubmenuClose: action.payload,
      };
    }
    case 'onSetExpanded': {
      return {
        ...state,
        expanded: action.payload,
      };
    }
    case 'onSetNextSubmenu': {
      return {
        ...state,
        nextSubmenu: action.payload,
      };
    }
    case 'onOpenNextSubmenu': {
      return {
        ...state,
        expanded: action.payload,
        nextSubmenu: false,
        isSubmenuClose: false,
      };
    }
    case 'onSetInitialState': {
      return {
        ...state,
        shouldOpenMenu: false,
        expanded: false,
        nextSubmenu: false,
        isSubmenuClose: true,
      };
    }
    default: {
      return { ...state };
    }
  }
}
